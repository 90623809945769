import {useApiRequestMappers} from "@/shared/composables/use-api-request-mappers";
import {useApplicationContext} from "@/shared/composables/use-application-context";

export const useAgencyApi = () => {
  const {logger, httpClient} = useApplicationContext();
  const {keysToSnakeCase, keysToCamel} = useApiRequestMappers();

  const listAgencies = async () => {
    try {
      logger.info(`Fetching all agencies`);
      const response = await httpClient.get('/agencies');
      const agencies = response.data;

      logger.debug(`Retrieved (${agencies.length}) agency(s)`, agencies);
      return agencies;
    } catch (error) {
      logger.error(`Failed to fetch all agencies`, error);
      throw  error;
    }
  };

  const getAgency = async (id) => {
    try {
      logger.info(`Fetching information for agency (${id})`);
      const response = await httpClient.get(`/agencies/${id}`);
      const agency = keysToCamel(response.data);

      logger.debug(`Retrieved agency (${id}) information`, agency);
      return agency;
    } catch (error) {
      logger.error(`Failed to fetch agency (${id}) information`, error);
      throw  error;
    }
  };

  const updateAgency = async (id, data) => {
    try {

      const requestData = keysToSnakeCase(data);
      delete requestData['id'];
      //delete requestData['name'];
      //delete requestData['additional_information'];
      if (requestData['additional_information']){
        requestData.additional_info = [{
          name: 'branch_number',
          value: requestData['additional_information'].branch_number
        }]
        delete requestData['additional_information'];
      }



      logger.info(`updating agency (${id}) information`);
      logger.debug('agency information sent to the API to update', requestData);
      await httpClient.patch(`/agencies/${id}`, {...requestData});
    } catch (error) {
      logger.error(`Updating agency (${id}/${data.name}) request failed`, error);
      throw  error;
    }
  };

  const fetchAllReservations = async (id) => {
    try {
      logger.info(`Fetching all reservations for agency (${id})`);
      const response = await httpClient.get(`/agencies/${id}`);
      const agency = keysToCamel(response.data);

      logger.debug(`Retrieved agency (${id}) information`, agency);
      return agency;
    } catch (error) {
      logger.error(`Failed to fetch agency (${id}) information`, error);
      throw  error;
    }
  };

  return {
    listAgencies,
    getAgency,
    updateAgency,
    fetchAllReservations
  };
};
