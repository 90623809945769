import {useApiRequestMappers} from "@/shared/composables/use-api-request-mappers";
import {useApplicationContext} from "@/shared/composables/use-application-context";

export const useUserApi = () => {
  const {logger, httpClient} = useApplicationContext();
  const {keysToSnakeCase, keysToCamel} = useApiRequestMappers();

  const AGENCY_ADMIN = "agency_admin";
  const AGENCY_AGENT = "agency_agent";

  const updateUser = async (data) => {
    const requestData = keysToSnakeCase(data);
    const id = requestData.id;

    delete requestData.id;
    
    let role = requestData.role;

    delete requestData.role;

    try {
      logger.info(`updating user (${id}/${requestData.first_name} ${requestData.last_name})`);
      logger.debug('Group basic information sent to the API to update group', requestData);
      await httpClient.put(`/users/${id}/profile`, {...requestData});

      if (id != "me") {
        await httpClient.put(`/users/${id}/roles`, {role: role});
      }

      return id;
    } catch (error) {
      logger.error(`Updating group (${id}/${requestData.name} ${requestData.last_name}) request failed`, error);
      throw  error;
    }
  };

  const addUserToAgency = async (data) => {
    const requestData = keysToSnakeCase(data);

    let role = AGENCY_AGENT;

    if (requestData.is_admin === 'true') {
      role = AGENCY_ADMIN;
    }

    const body = {
      resource:{
        role: role,
        id: requestData.agency_id
      },
      profile: {
          first_name: requestData.agent_first_name,
          last_name: requestData.agent_last_name,
          email: requestData.agent_email,
      }
    }

    try {
      logger.info(`updating user (${requestData.agency_id}/${body})`);
      logger.debug('Group basic information sent to the API to update group', body);
      await httpClient.post(`/users`, {...body});
    } catch (error) {
      logger.error(`Updating group (${requestData.agency_id}/${body}) request failed`, error);
      throw  error;
    }
  };

  const fetchMembersOfAgency = async (id) => {
    const AGENCY = "AGENCY"
    try {

      logger.debug('get all members', id);
      return await httpClient.get(`/users?resource=${AGENCY}&id=${id}`);

    } catch (error) {
      logger.error(`fetch all members failed`, error);
      throw  error;
    }
  };

  const getUserProfile = async (userId) => {
    try {
      logger.info(`Getting user profile of user (${userId})`);
      const response = await httpClient.get(`/users/${userId}`);
      const userProfile = keysToCamel(response.data);

      logger.debug(`Retrieved user (${userId}) profile`, userProfile);
      return userProfile;
    } catch (error) {
      logger.error(`Failed to retrieve user (${userId}) profile`, error);
      throw  error;
    }
  };

  return {
    updateUser,
    addUserToAgency,
    fetchMembersOfAgency,
    getUserProfile
  };
};
