<template>
  <component :is="adminData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="adminData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No agency found with this agency id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'agencies'}"
        >
          Agency List
        </b-link>
        for other agency.
      </div>
    </b-alert>

    <b-tabs
      v-if="adminData"
      pills
    >

      <!-- Tab: Profile -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Profil</span>
        </template>
        
          <member-profile-edit
          :member-data="userFormData"
          class="mt-2 pt-75"
        />
        
      </b-tab>

      <!-- Tab: Information
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
         <user-edit-tab-information class="mt-2 pt-75" /> 
      </b-tab>
      -->

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { onMounted, reactive, ref } from '@vue/composition-api';
import router from '@/router'
import store from '@/store'
import MemberProfileEdit from './MemberProfileEdit.vue'
import {useUserApi} from '@/views/administrators/composables/use-user-api';
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import { useApplicationContext } from "@/shared/composables/use-application-context";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    MemberProfileEdit

  },
  setup() {
    const { logger, route } = useApplicationContext();
    const { getUserProfile, updateUserProfile } = useUserApi();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();

    const adminData = reactive({});
    let loadedUserData = reactive({});
    let userFormData = reactive({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      avatar: '',
      role: '',
      pcVoyagesAgentCode: ''
    });
    const ADMIN_APP_STORE_MODULE_NAME = 'app-agencies'


    onMounted(async () => {
      try {
        let userId = route.value.params.id;
        let user = await getUserProfile(userId);

        loadedUserData = reactive(user);
        Object.assign(userFormData, user);
      } catch (error) {
        logger.error(error);
      }
    });





    return {
      adminData,
      userFormData
    }
  },
}
</script>

<style>

</style>
